import axios from 'axios';
import querystring from 'qs';
import {
    AUTH_REQUEST, AUTH_ERROR, AUTH_SUCCESS, AUTH_LOGOUT, AUTH_REQUEST_IMPERSONATE,
    AUTH_SUCCESS_IMPERSONATE, AUTH_EXACHANGEUSER, AUTH_ADMINUSER, SET_ROLE
} from '../actions/authactions';
import * as cookie from 'tiny-cookie';

const USERCOOKIE = 'usersettings';
const USERADMINCOOKIE = 'useradminsettings';

let user = null;
const userCookieValue = cookie.get(USERCOOKIE);
if (userCookieValue)
    user = JSON.parse(userCookieValue);
else
    user = {
        token: null,
        role: null,
        username: null,
        parentid: null,
        isIndividualReportVisible: false
    };
let useradmin = null;
const userAdminCookieValue = cookie.get(USERADMINCOOKIE);
if (userAdminCookieValue)
    useradmin = JSON.parse(userAdminCookieValue);
else
    useradmin = {
        token: null,
        role: null,
        username: null,
        parentid: null,
        isIndividualReportVisible: false
    };

const state = { user: user, testenvironment: true, status: '', useradmin: useradmin };

const getters = {
    isAuthenticated: state => !!state.user.token,
    authStatus: state => state.status,
    user: state => state.user,
    testenvironment: state => state.testenvironment,
    isAdministrator: state => state.user.role === 'Administrator',
    isAttendant: state => state.user.role === 'Attendant',
    isClient: state => state.user.role === 'Client',
    isClientReadOnly: state => state.user.role === 'ClientReadOnly',
    isLimitedAdministrator: state => state.user.role === 'AdministratorLimited',
    isSubAccount: state => state.user.parentid != '0',
    useradmin: state => state.useradmin,
    isCoordinatingAttendant: state => state.user.role === 'CoordinatingAttendant',
    isIndividualReportVisible: state => state.user.isIndividualReportVisible == 'True'
};

const actions = {
    [AUTH_REQUEST]: ({ commit }, user) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST);
            axios.post(APIURL + '/connect/token', querystring.stringify({ grant_type: 'password', username: user.username, password: user.password, scope: 'roles' }))
                .then(resp => {
                    commit(AUTH_SUCCESS, resp);
                    //resolve(resp);
                    axios.get(APIURL + '/applicationuser/me')
                        .then(resp2 => {
                            commit(SET_ROLE, resp2);
                            resolve(resp2);
                        })
                        .catch(err => {
                            commit(AUTH_ERROR, err);
                            reject(err);
                        });
                })
                .catch(err => {
                    commit(AUTH_ERROR, err);
                    reject(err);
                });
        });
    },
    [AUTH_REQUEST_IMPERSONATE]: ({ commit }, user) => {
        commit(AUTH_ADMINUSER);
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST_IMPERSONATE);
            axios.post(APIURL + '/connect/token', querystring.stringify({ grant_type: 'password', username: user.username, password: user.password, scope: 'roles', loginHint: 'impersonate' }))
                .then(resp => {
                    commit(AUTH_SUCCESS_IMPERSONATE, resp);
                    //resolve(resp);
                    axios.get(APIURL + '/applicationuser/me')
                        .then(resp2 => {
                            commit(SET_ROLE, resp2);
                            resolve(resp2);
                        })
                        .catch(err => {
                            commit(AUTH_ERROR, err);
                            reject(err);
                        });
                })
                .catch(err => {
                    commit(AUTH_ERROR, err);
                    reject(err);
                });
        });
    },
    [AUTH_LOGOUT]: ({ commit }) => {
        return new Promise((resolve) => {
            commit(AUTH_LOGOUT);
            resolve();
        });
    },
    [AUTH_EXACHANGEUSER]: ({ commit }) => {
        //return this.AUTH_REQUEST_IMPERSONATE({ username: state.useradmin.username, password: state.useradmin.username });       
        commit(AUTH_EXACHANGEUSER);
    }
};

const mutations = {
    [AUTH_REQUEST]: (state) => {
        state.status = 'loading';
    },
    [AUTH_REQUEST_IMPERSONATE]: (state) => {
        state.status = 'loading';
    },
    [AUTH_SUCCESS]: (state, resp) => {
        state.status = 'success';
        state.user.token = resp.data.access_token;
        //state.user.role = resp.data.role;
        //state.user.username = resp.data.username;
        //state.user.parentid = resp.data.parentid;
        //state.user.isIndividualReportVisible = resp.data.isIndividualReportVisible;
        state.user.loginHint = '';
        cookie.set(USERCOOKIE, JSON.stringify(state.user), Infinity);
        // Here set the header of your ajax library to the token value.
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + resp.data.access_token;
        state.testenvironment = resp.data.testenvironment;
    },
    [AUTH_SUCCESS_IMPERSONATE]: (state, resp) => {
        state.status = 'success';
        state.user.token = resp.data.access_token;
        //state.user.role = resp.data.role;
        //state.user.username = resp.data.username;
        //state.user.parentid = resp.data.parentid;
        //state.user.isIndividualReportVisible = resp.data.isIndividualReportVisible;
        state.user.loginHint = 'impersonate';
        cookie.set(USERCOOKIE, JSON.stringify(state.user), Infinity);
        // Here set the header of your ajax library to the token value.
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + resp.data.access_token;
        state.testenvironment = resp.data.testenvironment;
    },
    [AUTH_ERROR]: (state) => {
        state.status = 'error';
        state.user = {
            token: null,
            role: null,
            username: null,
            parentid: null,
            isIndividualReportVisible: false
        };
        cookie.remove(USERCOOKIE);
    },
    [AUTH_LOGOUT]: (state) => {
        state.user = {
            token: null,
            role: null,
            username: null,
            parentid: null,
            isIndividualReportVisible: false
        };
        state.useradmin = {
            token: null,
            role: null,
            username: null,
            parentid: null,
            isIndividualReportVisible: false
        };
        cookie.remove(USERCOOKIE);
        cookie.remove(USERADMINCOOKIE);
        // remove the axios default header
        delete axios.defaults.headers.common['Authorization'];
    },
    [AUTH_EXACHANGEUSER]: (state) => {
        state.user.username = state.useradmin.username;
        state.user.parentid = state.useradmin.parentid;
        state.user.role = state.useradmin.role;
        state.user.token = state.useradmin.token;
        state.user.isIndividualReportVisible = state.useradmin.isIndividualReportVisible;
        state.user.loginHint = '';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + state.user.token;
        cookie.set(USERCOOKIE, JSON.stringify(state.user), Infinity);
        cookie.remove(USERADMINCOOKIE);
        state.useradmin.username = null;
        state.useradmin.role = null;
        state.useradmin.token = null;
        state.useradmin.parentid = null;
        state.useradmin.isIndividualReportVisible = false;

    },
    [AUTH_ADMINUSER]: (state) => {
        cookie.set(USERADMINCOOKIE, JSON.stringify(state.user), Infinity);
        state.useradmin.username = state.user.username;
        state.useradmin.role = state.user.role;
        state.useradmin.token = state.user.token;
        state.useradmin.parentid = state.user.parentid;
        state.useradmin.isIndividualReportVisible = state.user.isIndividualReportVisible;
    },
    [SET_ROLE]: (state, resp) => {
        state.user.role = resp.data.role;
        state.user.username = resp.data.username;
        state.user.parentid = resp.data.parentid;
        state.testenvironment = resp.data.testenvironment;
        state.user.fullName = resp.data.fullName;
        state.user.isIndividualReportVisible = resp.data.isIndividualReportVisible;
        cookie.set(USERCOOKIE, JSON.stringify(state.user), Infinity);
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};